/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import Helmet from "react-helmet";
import Locale from "../data/contexts/riotLocale";
import { isRTL } from "../utils/isRTL";

interface Params {
  description?: string;
  meta?: HTMLMetaElement[];
  title: string;
}

const SEO: React.FC<Params> = ({ description = "", meta = [], title }) => {
  const {
    contentstackWebsiteConfiguration: { favicon },
  } = useStaticQuery(
    graphql`
      query {
        contentstackWebsiteConfiguration {
          favicon {
            url
          }
        }
      }
    `,
  );
  const locale = useContext(Locale);
  const lang = locale.slice(0, 2);
  const metaDescription = description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
        dir: isRTL(locale) ? "rtl" : "ltr",
      }}
      title={title}
      link={[{ rel: "icon", type: "image/svg", href: favicon?.url }]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@WildRift",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1, maximum-scale=1`,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
